<template>
  <div>
    <header class="header">
      <div class="store-img">
        <img :src="avatar ? avatar : icon_member_default" alt=""/>
      </div>
      <div class="store-name">{{ payOrderInfo.mchName }}</div>
    </header>
    <div class="plus-input">
      <div class="pay-title">
        付款金额
      </div>
      <div class="pay-box">
        <div class="S">
          <img src="../../assets/icon/S.svg" alt=""/>
        </div>
        <!-- 手写输入框 -->
        <div class="input-c">
          <div class="input-c-div-1">{{amount}}</div>
          <!-- 数字金额后边的光标 -->
          <div class="input-c-div"></div>
        </div>
      </div>

    </div>

    <!-- 备注板块 ，目前不需要添加备注，隐藏-->
    <div class="remark-k" :class="payType != 'wx' ? 'margin-top-30' : ''">
      <div class="remark">
        <div class="remark-hui" v-show="remark">{{ remark }}</div>
        <div @click="myDialogStateFn">{{ remark ? "修改" : "添加备注" }}</div>
      </div>
    </div>
    <!-- dialog 对话框 目前不需要添加备注，隐藏-->
    <MyDialog
        v-show="myDialogState"
        @myDialogFn="myDialogFn"
        @cancelDialog="cancelDialog"
        :remark="remark"
    >
    </MyDialog>
    <DialogOpen
        v-show="DialogOpenState"
        @cancelDialog="cancelDialog"
        :error="error"
    >
    </DialogOpen>

    <!-- 键盘板块 目前不需要键盘 隐藏 -->
    <div class="keyboard-plus" v-if="isAllowModifyAmount">
      <Keyboard
          @delTheAmount="delTheAmount"
          @conceal="conceal"
          @enterTheAmount="enterTheAmount"
          @payment="pay"
          :money="amount"
          :concealSate="concealSate"
          :typeColor="typeColor[payType]"
      ></Keyboard>
    </div>

    <!-- ypay中，付款的点击事件 由 payment 修改为 pay  -->
    <!-- ypay中，付款页面是唯一的，颜色不在需要v-bind，去掉即可 -->
    <div class="bnt-pay" v-if="!isAllowModifyAmount">
      <div class="bnt-pay">
        <div
            class="bnt-pay-text"
            style="background-color:#1792e8"
            @click="pay"
        >
          付款
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyDialog from "./../dialog/dialog";  // 添加备注弹出的对话框
import DialogOpen from "./../dialog/dialogOpen";  // 提示框内容
import Keyboard from "./../keyboard/keyboard";  // 手写键盘
import { getPayInfo, getPaysPackage} from '@/api/api'
import config from "@/config";

export default {
  // 注册备注对话框，和 手写键盘组件，由于这里是直接掉起支付事件，所以目前不应用
  // eslint-disable-next-line vue/no-unused-components
  components: {Keyboard,MyDialog,DialogOpen},
  data: function () {
    return {
      payType:'#07c160',
      error:'',
      remark:'',
      merchantName: 'ypay',  // 付款的商户默认
      avatar: require("../../assets/images/store.png"), // 商户头像默认
      amount: '',  // 支付金额默认
      resData: {},
      wxImg: require("../../assets/icon/wx.svg"), // 微信支付图片
      payOrderInfo: {}, //订单信息
      concealSate:'',
      myDialogState:false,
      DialogOpenState:false,
      isAllowModifyAmount:true,
      typeColor:'',
    }
  },

  mounted() {
     this.setPayOrderInfo(); //获取订单信息 & 调起支付插件
  },

  methods: {

    setPayOrderInfo() {
      const that = this
      getPayInfo().then(res => {
        that.payOrderInfo = res
      }).catch(res => {
        that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
      });
    },
    myDialogStateFn(){
      this.myDialogState=true;
    },
    myDialogFn(event){
      this.remark=event
       this.myDialogState=false;
    },
    cancelDialog(){
      this.myDialogState=false;
      this.DialogOpenState=false
    },
    enterTheAmount(event){
      /*判断总体数字是否超过10为*/
       let strs=this.amount+event;
       if(strs.length>10){
          return;
       }
       /*是否存在小数点，存在了不能再去点击*/
      if(this.amount.includes('.') && event=='.'){
        return;
      }
      /*是否超过两位小数点*/
      if(this.amount.toString().match(/^\d+(\.\d{2})+\.?$/)){
         return;
      }
      /*是否第一个选的是点号*/
      if(this.amount=='' && event=='.' || this.amount=='' && event==0){
        if(event==0){
          this.amount=0+'.';
        }else{
          this.amount=0+event;
        }
      }else{
        this.amount=this.amount+''+event
      }
    },
    delTheAmount(){
      this.amount=this.amount.slice(0, -1);
    },
    conceal(){

    },
    payment(){

    },
    // 支付事件
    pay: function () {
      // 该函数执行效果慢
      let that = this;
      if(this.amount==''){
         return;
      }
      if(this.amount<=0){
        this.error='请输入正确的金额';
        this.DialogOpenState=true;
        return;
      }
      getPaysPackage(this.amount).then(res => {

        if (res.code != '0') {
          this.error=res.msg;
          this.DialogOpenState=true;
          return;
        }

        if (res.data.orderState != 1) { //订单不是支付中，说明订单异常
          this.error=res.data.errMsg;
          this.DialogOpenState=true;
          return;
        }

        that.resData = res.data;
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady, false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady);
            document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady);
          }
        } else {
          that.onBridgeReady();
        }

      }).catch(res => {
        that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
      });
    },


    /* 唤醒微信支付*/
    onBridgeReady() {

      let that = this;

      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke(
          'getBrandWCPayRequest', JSON.parse(that.resData.payInfo),
          function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              // //重定向
              if (that.payOrderInfo.returnUrl) {
                location.href = that.payOrderInfo.returnUrl;
              } else {
                this.error='支付成功！';
                this.DialogOpenState=true;
                window.WeixinJSBridge.call('closeWindow')
              }

            }
            if (res.err_msg == "get_brand_wcpay_request:cancel") {
              // this.error='支付取消！';
              // this.DialogOpenState=true;
              // window.WeixinJSBridge.call('closeWindow')
            }
            if (res.err_msg == "get_brand_wcpay_request:fail") {
              //alert('支付失败:' + JSON.stringify(res))

              // window.WeixinJSBridge.call('closeWindow')
            }
            if (res.err_msg == "total_fee") {
              //alert('缺少参数')
              // window.WeixinJSBridge.call('closeWindow')
            }
          }
      );
    },

  }


}
</script>
<style lang="css" scoped>
@import './pay.css';
</style>
